<template>
	<v-card class="shadow-blue pa30 redaction-total-points-container">
		<h3 class="ortho-results-title mb-1">Points gagnés</h3>
		<p class="ortho-description ortho-text-gray">Les points gagnés sur les notions vues sur le parcours</p>
		<div class="progression-block mx-auto pt-1 pb-2">
			<ProgressionBarChartSession :parcours="parcours" />
		</div>
	</v-card>
</template>

<script>
import ProgressionBarChartSession from '@/components/charts/ProgressionBarChartSession';

export default {
	name: 'TotalEarnedPointsRedaction',
	components: { ProgressionBarChartSession },
	props: {
		parcours: Object,
	},
};
</script>

<style lang="scss" scoped>
.redaction-total-points-container {
	.progression-block {
		max-width: 600px;
	}
}
</style>
