<template>
	<v-container>
		<div class="report-formation-container">
			<CongrateFinishFormation
				:parcours="thisParcours"
				:class="{ 'mt-7': $vuetify.breakpoint.mdAndUp, 'mt-2': $vuetify.breakpoint.smAndDown }"
			/>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<IntroduceOtherParcoursCarousel
				v-if="loading === false && parcoursNotOwned.length !== 0"
				:formationConfig="formationProgress.config"
				:parcoursNotOwned="parcoursNotOwned"
			/>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<TotalEarnedPointsRedaction :parcours="thisParcours" />
			<v-btn
				v-if="loading === false && parcoursNotOwned.length === 0"
				depressed
				:x-large="$vuetify.breakpoint.mdAndUp"
				:large="$vuetify.breakpoint.smAndDown"
				dark
				color="#7854f7"
				class="next-btn hvr-grow"
				:to="{ name: 'Parcours Detail', params: { idParcours: thisParcours._id } }"
			>
				<span class="white--text">Retourner à mon espace perso</span>
			</v-btn>
			<v-btn
				v-if="loading === false && parcoursNotOwned.length > 0"
				depressed
				:x-large="$vuetify.breakpoint.mdAndUp"
				:large="$vuetify.breakpoint.smAndDown"
				dark
				color="#7854f7"
				class="next-btn hvr-grow"
				target="_blank"
				href="https://meetings-eu1.hubspot.com/rendez-vous-csm?uuid=2e0446cd-e95e-42a1-b311-a21d2b36ba22?utm_campaign=end-parcours&utm_source=main-cta-report"
			>
				<span class="white--text">Débloquer un nouveau Parcours</span>
			</v-btn>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
		</div>
	</v-container>
</template>

<script>
import CongrateFinishFormation from '@/components/reports/formation/CongrateFinishFormation';
import IntroduceOtherParcoursCarousel from '@/components/reports/formation/IntroduceOtherParcoursCarousel';
import TotalEarnedPointsRedaction from '@/components/reports/parcours/TotalEarnedPointsRedaction.vue';
import { changeBgColorById } from '@/utils/css-util.js';
import { setZindexByClassNames, setDisplay } from '@/utils/helper.js';

export default {
	name: 'RedactionFormationReport',
	components: { CongrateFinishFormation, IntroduceOtherParcoursCarousel, TotalEarnedPointsRedaction },
	data() {
		return { formationProgress: null, thisParcours: null, loading: false, parcoursNotOwned: [] };
	},
	async created() {
		this.loading = true;
		this.formationProgress = await this.$store.dispatch('profile/formationProgress', {
			idFormation: this.$route.params.idFormation,
		});

		this.thisParcours = this.findParcours(this.$route.params.idParcours, this.formationProgress.config.parcours_list);
		this.parcoursNotOwned = await this.getParcoursNotOwned();
		this.loading = false;
	},
	mounted() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		changeBgColorById('reportIndex', '#120136');
		setZindexByClassNames(['navbar'], 1);
		if (window.innerWidth < 500) setDisplay('cc-157aw', 'none', 'important'); // Hide chat support
	},
	beforeDestroyed() {
		if (window.innerWidth < 500) setDisplay('cc-157aw', 'block'); // Unhide chat support
	},
	methods: {
		async getParcoursNotOwned() {
			const parcoursUserNotHave = [];
			const allTopics = await this.$store.dispatch('resources/getAllTopicsAndParcours');
			const parcoursOfUser = await this.$store.dispatch('parcours/getAllParcoursByUserId', {
				userId: this.formationProgress.formation.user_id,
			});

			allTopics.forEach((topic) => {
				topic.parcours.forEach((p) => {
					p.topicName = topic.name;
					const parcoursIndex = parcoursOfUser.findIndex((userParcours) => userParcours._id === p._id);
					if (parcoursIndex === -1) parcoursUserNotHave.push(p);
				});
			});

			return parcoursUserNotHave;
		},
		findParcours(parcoursId, parcoursList) {
			const indexOfParcours = parcoursList.findIndex((parcoursItem) => parcoursItem.parcours_id._id === parcoursId);
			return parcoursList[indexOfParcours].parcours_id;
		},
	},
};
</script>

<style lang="scss" scoped>
.report-formation-container {
	margin: 0 auto;
	padding-bottom: 40px;
	max-width: 800px;
	min-height: 100vh;

	.next-btn {
		position: fixed;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
	}
}
</style>
